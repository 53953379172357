/* eslint-disable @typescript-eslint/ban-ts-comment */
// Need to have this import and the next-transpile-modules config for this package in order to pass build
import { getTicketingHref } from "@components/TicketingLink";
import { env } from "@env/client.mjs";
import { applicationChain } from "@utils/applicationChain";
import { CHAIN_NAMESPACES } from "@web3auth/base";
import { EthereumPrivateKeyProvider } from "@web3auth/ethereum-provider";
import { Web3AuthNoModal } from "@web3auth/no-modal";
import { OpenloginAdapter } from "@web3auth/openlogin-adapter";
import { Web3AuthConnector } from "@web3auth/web3auth-wagmi-connector";

type ConnectorConstructor = ConstructorParameters<typeof Web3AuthConnector>[0];
type ConnectorConstructorWithoutProvider = Omit<
  ConnectorConstructor,
  "options"
> & {
  options: Omit<ConnectorConstructor["options"], "loginParams">;
};

export class Web3GoogleConnector extends Web3AuthConnector {
  // @ts-ignore
  readonly id = "web3auth:google";
  // @ts-ignore
  readonly name = "Google";
  constructor(opts: ConnectorConstructorWithoutProvider) {
    super({
      ...opts,
      options: {
        ...opts?.options,
        loginParams: {
          loginProvider: "google",
        },
      },
    });
  }
}
export class Web3FacebookConnector extends Web3AuthConnector {
  // @ts-ignore
  readonly id = "web3auth:facebook";
  // @ts-ignore
  readonly name = "Facebook";
  constructor(opts: ConnectorConstructorWithoutProvider) {
    super({
      ...opts,
      options: {
        ...opts?.options,
        loginParams: {
          loginProvider: "facebook",
        },
      },
    });
  }
}
export class Web3EmailConnector extends Web3AuthConnector {
  // @ts-ignore
  readonly id = "web3auth:email";
  // @ts-ignore
  readonly name = "Email";
  constructor({
    email,
    ...opts
  }: ConnectorConstructorWithoutProvider & { email: string }) {
    super({
      ...opts,
      options: {
        ...opts?.options,
        loginParams: {
          loginProvider: "email_passwordless",
          extraLoginOptions: {
            login_hint: email,
            // domain: typeof window !== undefined ? window.origin : "",
          },
        },
      },
    });
  }
  setEmail(email: string) {
    if (this?.loginParams?.extraLoginOptions)
      this.loginParams.extraLoginOptions.login_hint = email;
  }
}

// Create Web3Auth Instance
// const name = "Spaceship Foundation";
const chainConfig = {
  chainNamespace: CHAIN_NAMESPACES.EIP155,
  applicationChainId: "0x" + applicationChain.id.toString(16),
  rpcTarget: applicationChain.rpcUrls.default.http[0] ?? "",
  displayName: applicationChain.name,
  tickerName: applicationChain.nativeCurrency?.name,
  ticker: applicationChain.nativeCurrency?.symbol,
  blockExplorer: applicationChain?.blockExplorers?.[0]?.url ?? "",
};

const web3AuthNoModalInstance = new Web3AuthNoModal({
  clientId: env.NEXT_PUBLIC_WEB3AUTH_CLIENT_ID,
  chainConfig,
  // @ts-ignore
  web3AuthNetwork: env.NEXT_PUBLIC_WEB3AUTH_NETWORK,
});

const privateKeyProvider = new EthereumPrivateKeyProvider({
  config: {
    chainConfig: {
      chainId: "0x" + applicationChain.id.toString(16),
      blockExplorer:
        applicationChain.blockExplorers?.default.url ??
        "https://polygonscan.com/",
      displayName: applicationChain.name,
      rpcTarget: applicationChain.rpcUrls.default.http[0] ?? "",
      tickerName: applicationChain.nativeCurrency?.symbol ?? "MATIC",
      ticker: "MATIC",
    },
  },
});

// Add openlogin adapter for customisations
const openloginAdapterInstance = new OpenloginAdapter({
  privateKeyProvider,
  adapterSettings: {
    uxMode: "redirect",
    redirectUrl:
      typeof window !== "undefined"
        ? window.location.origin +
          "/login/success/" +
          encodeURIComponent(getTicketingHref("/"))
        : "",
    // @ts-ignore
    network: env.NEXT_PUBLIC_WEB3AUTH_NETWORK,
  },
});

// web3AuthInstance.configureAdapter(openloginAdapterInstance);
web3AuthNoModalInstance.configureAdapter(openloginAdapterInstance);
// Add Torus Wallet Plugin (optional)
// const torusPlugin = new TorusWalletConnectorPlugin({
//   torusWalletOpts: {
//     buttonPosition: "bottom-left",
//   },
//   walletInitOptions: {
//     whiteLabel: {
//       theme: {
//         isDark: true,
//         colors: {
//           torusBrand1: "#E35600",
//         },
//       },
//       logoDark: "https://marketplace-base.vercel.app/img/spaceship_icon.png",
//       logoLight:
//         "https://marketplace-base.vercel.app/img/spaceship_icon_white.png",
//       topupHide: true,
//       featuredBillboardHide: true,
//       disclaimerHide: true,
//     },
//     useWalletConnect: true,
//   },
// });

// // web3AuthInstance.addPlugin(torusPlugin);
// void web3AuthNoModalInstance.addPlugin(torusPlugin);

export const setRedirectUrl = (url: string) => {
  openloginAdapterInstance.setAdapterSettings({
    redirectUrl: `${window.location.origin}/login/success/${encodeURIComponent(
      url
    )}`,
  });
};
export const getWeb3AuthInstance = (modal = false) => {
  void modal;
  // if (modal) {
  //   return web3AuthInstance;
  // } else {
  return web3AuthNoModalInstance;
  // }
};
