import { setRedirectUrl as setWeb3AuthRedirectUrl } from "@components/web3/web3Auth";
import { env } from "@env/client.mjs";
import {
  type UseMutationOptions,
  useMutation,
  useQuery,
  useQueryClient,
} from "@tanstack/react-query";
import type { ConnectResult, Provider } from "@wagmi/core";
import { signOut } from "next-auth/react";
import { useRouter } from "next/router";
import { useEffect } from "react";

const chain = import("@utils/applicationChain");
const core = import("@wagmi/core");
const publicProviders = import("@wagmi/core/providers/public");
const jsonRPCProviders = import("@wagmi/core/providers/jsonRpc");
const alchemyProviders = import("@wagmi/core/providers/alchemy");
const injectedConnector = import("wagmi/connectors/injected");
// const walletConnectConnector = import("wagmi/connectors/walletConnect");
const w3aConnectors = import("@components/web3/web3Auth");

export const useWagmi = () => {
  const { data, ...rest } = useQuery({
    queryKey: ["wagmi-core"],
    queryFn: async () => {
      const [
        { InjectedConnector },
        // { WalletConnectConnector },
        { applicationChain },
        { createClient, configureChains },
        { publicProvider },
        { jsonRpcProvider },
        { Web3FacebookConnector, Web3GoogleConnector, getWeb3AuthInstance },
      ] = await Promise.all([
        injectedConnector,
        // walletConnectConnector,
        chain,
        core,
        publicProviders,
        jsonRPCProviders,
        w3aConnectors,
      ]);

      const { chains, provider } = configureChains(
        [applicationChain],
        [publicProvider()]
      );

      const client = createClient({
        autoConnect: true,
        provider,
        // webSocketProvider,
      });

      return {
        client,
        core: await core,
        connectors: {
          injected: new InjectedConnector({
            chains,
            options: { shimDisconnect: true },
          }),
          // walletConnect: new WalletConnectConnector({
          //   options: {
          //     projectId: env.NEXT_PUBLIC_WALLETCONNECT_KEY,
          //   },
          //   chains,
          // }),
          google: new Web3GoogleConnector({
            chains,
            options: {
              web3AuthInstance: getWeb3AuthInstance(false),
            },
          }),
          facebook: new Web3FacebookConnector({
            chains,
            options: {
              web3AuthInstance: getWeb3AuthInstance(false),
            },
          }),
        },
        chains,
      };
    },
    staleTime: Infinity,
  });
  return {
    wagmi: data,
    ...rest,
  };
};

export const useAccount = () => {
  const { wagmi } = useWagmi();
  const queryClient = useQueryClient();
  useEffect(() => {
    if (wagmi)
      return wagmi.core.watchAccount((acc) => {
        if (!acc) localStorage.clear();
        return queryClient.invalidateQueries(["wagmi"]);
      });
  }, [wagmi, queryClient]);
  const { data } = useQuery({
    queryKey: ["wagmi", "account"],
    queryFn: () => {
      if (!wagmi) return null;
      return wagmi.core.getAccount();
    },
    enabled: !!wagmi,
    initialData: null,
  });
  return (
    data || {
      address: undefined,
      connector: undefined,
      isConnected: false,
      isReconnecting: false,
      isConnecting: false,
      isDisconnected: true,
      status: "disconnected",
    }
  );
};

export const useNetwork = () => {
  const { wagmi } = useWagmi();
  const queryClient = useQueryClient();
  useEffect(() => {
    if (wagmi)
      return wagmi.core.watchNetwork(() =>
        queryClient.invalidateQueries(["wagmi"])
      );
  }, [wagmi, queryClient]);
  const { data } = useQuery({
    queryKey: ["wagmi", "network"],
    queryFn: () => {
      if (!wagmi) return null;
      return wagmi.core.getNetwork();
    },
    enabled: !!wagmi,
    initialData: null,
  });
  return data;
};

export const useSigner = () => {
  const { wagmi } = useWagmi();
  const queryClient = useQueryClient();
  useEffect(() => {
    if (wagmi)
      return wagmi.core.watchSigner({}, () =>
        queryClient.invalidateQueries(["wagmi"])
      );
  }, [wagmi, queryClient]);
  const query = useQuery({
    queryKey: ["wagmi", "signer"],
    queryFn: () => {
      if (!wagmi) return null;
      return wagmi.core.fetchSigner();
    },
    enabled: !!wagmi,
    initialData: null,
  });
  return query;
};

export const useProvider = () => {
  const { wagmi } = useWagmi();
  const queryClient = useQueryClient();
  useEffect(() => {
    if (wagmi)
      return wagmi.core.watchProvider({}, () =>
        queryClient.invalidateQueries(["wagmi"])
      );
  }, [wagmi, queryClient]);
  const { data } = useQuery({
    queryKey: ["wagmi", "provider"],
    queryFn: () => {
      if (!wagmi) return null;
      return wagmi.core.getProvider();
    },
  });
  return data;
};

type UseConnectArg = {
  connector: keyof NonNullable<
    ReturnType<typeof useWagmi>["wagmi"]
  >["connectors"];
  reauth?: boolean;
};

type UseConnectOptions = Omit<
  UseMutationOptions<
    ConnectResult<Provider> | undefined,
    unknown,
    UseConnectArg,
    unknown
  >,
  "mutationFn" | "mutationKey"
>;

export const useConnect = (opts?: UseConnectOptions) => {
  const { wagmi } = useWagmi();

  const router = useRouter();
  return useMutation({
    mutationKey: ["wagmi", "connect"],
    mutationFn: async ({ connector, reauth = true }: UseConnectArg) => {
      if (router && router.query && typeof router.query.redirect === "string") {
        setWeb3AuthRedirectUrl(router.query.redirect);
      }
      await wagmi?.core.disconnect();
      if (reauth) await signOut({ redirect: false });
      localStorage.setItem("wagmi:connector", connector);
      return wagmi?.core
        .connect({
          connector: wagmi?.connectors[connector],
        })
        .catch((err) => {
          if (
            err instanceof wagmi.core.UserRejectedRequestError &&
            connector !== "injected"
          )
            return undefined;
          throw err;
        });
    },
    ...opts,
  });
};
// type WagmiCore = Awaited<typeof core>;
// type WagmiGets = Extract<keyof WagmiCore, `get${string}`>;
//
// type WagmiWatches = Extract<keyof WagmiCore, `watch${string}`>;
